import {graphql, Link} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TestimonialList from "../components/testimonials"
import {SharpImage} from "../utils/types"

interface Props {
  readonly data: PageQueryData
}

const LandingPageTemplate: React.FC<Props> = ({data}) => {
  const landingPage = data.landingPage
  const signUpURL = "https://tercen.com/_signup"
  const googleSignUpURL =
    "https://accounts.google.com/o/oauth2/auth?client_id=653844972403-ve6tjq31861siedt2pqp1h6ilk5kgbvk.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Ftercen.com%2F%3Fauth.provider%3Dgoogle&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline"

  return (
    <Layout title={landingPage.title} description={landingPage.metaDescription}>
      <header className="relative w-full bg-tercen-blue">
        <GatsbyImage
          alt="landing page header image"
          className="pt-64 md:pt-10"
          image={data.heroBackground.childImageSharp.gatsbyImageData}
          backgroundColor={`#2d74eb`}
          style={{maxHeight: "450px"}}
        />
        <div className="absolute top-0 left-0 right-0 container items-center py-10 flex flex-col md:flex-row">
          <div className="flex-1 px-4 w-full md:my-10 lg:px-8">
            <h1 className="text-white font-bold text-4xl md:text-3xl lg:text-5xl lg:pr-6">{landingPage.heroTitle}</h1>
            <h3 className="text-tercen-yellow text-2xl pt-2 md:text-lg lg:text-3xl lg:pt-2">
              {landingPage.heroTagline}
            </h3>
          </div>
          <div className="flex-1 w-full py-4 px-4 md:py-0 lg:px-32">
            <p className="hidden sm:block text-white text-xl font-medium">Let Tercen take care of it.</p>
            <p className="hidden sm:block text-white text-xl font-medium">Sign up here!</p>
            <div className="text-center py-4">
              <a
                href={signUpURL}
                className="bg-tercen-green-base transition duration-500 ease-in-out hover:bg-tercen-green-hover text-white py-2 rounded-sm font-bold inline-medium w-full block">
                Sign up with email
              </a>
              <p className="text-white font-bold py-5">OR</p>
              <a
                href={googleSignUpURL}
                className="overflow-hidden w-full py-2 px-3 bg-white transition duration-500 ease-in-out hover:bg-tercen-blue text-tercen-blue  hover:text-white flex flex-row rounded-sm">
                <svg className="w-4 h-6" viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                    fill="#4285f4"
                  />
                  <path
                    d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                    fill="#34a853"
                  />
                  <path
                    d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                    fill="#fbbc04"
                  />
                  <path
                    d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                    fill="#ea4335"
                  />
                </svg>
                <div className="flex-grow px-4 font-medium text-center">Continue with Google</div>
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="container py-12 flex flex-col md:flex-row items-center">
        <div className="flex-1 text-3xl px-6 py-6 md:py-0 text-tercen-blue sm:pr-20 lg:pr-40 text-center md:text-right">
          {landingPage.imageTagline}
        </div>
        <div className="flex-1 py-6 px-2 md:py-0 md:px-0">
          <video autoPlay loop muted playsInline>
            <source src={data.video_mp4.publicURL} type="video/mp4" />
            <source src={data.video_webm.publicURL} type="video/webm" />
          </video>
        </div>
      </section>
      <section className="w-full bg-tercen-light-blue px-4 md:px-0 md:py-12">
        <div className="container text-center flex flex-col md:flex-row">
          <div className="flex-1 py-6 px-4 md:py-0">
            <GatsbyImage
              className="inline-block align-middle w-1/6 md:w-1/4"
              image={data.icon1.childImageSharp.gatsbyImageData}
              alt="feature image"
            />
            <h3 className="text-2xl font-bold pt-6">Easy To Use</h3>
            <p className="text-sm pt-3">Take control of your data quickly. No coding necessary.</p>
          </div>
          <div className="flex-1 py-6 px-4 md:py-0">
            <GatsbyImage
              className="inline-block align-middle w-1/6 md:w-1/4"
              image={data.icon2.childImageSharp.gatsbyImageData}
              alt="feature image"
            />
            <h3 className="text-2xl font-bold pt-6">Flexible Visualisation</h3>
            <p className="text-sm pt-3">Build insightful views of your data in seconds. Simply drag and drop.</p>
          </div>
          <div className="flex-1 py-6 px-4 md:py-0">
            <GatsbyImage
              className="inline-block align-middle w-1/6 md:w-1/4"
              image={data.icon3.childImageSharp.gatsbyImageData}
              alt="feature image"
            />
            <h3 className="text-2xl font-bold pt-6">Work Together</h3>
            <p className="text-sm pt-3">Copy, share, contribute, and collaborate on all your projects effortlessly.</p>
          </div>
        </div>
      </section>
      <section className="w-full pt-20">
        <div className="container">
          <h1 className="text-center text-3xl font-medium">Testimonials</h1>
          <TestimonialList></TestimonialList>
        </div>
      </section>
      <section className="w-full pt-20">
        <div className="container text-center px-4 md:w-1/2 lg:w-1/3">
          <h1 className="text-center text-3xl font-medium">Sign Up Now</h1>
          <div className="text-center py-12">
            <a
              href={signUpURL}
              className="bg-tercen-green-base transition duration-500 ease-in-out hover:bg-tercen-green-hover text-white py-2 rounded-sm font-medium inline-block w-full">
              Sign up with email
            </a>
            <p className="font-bold py-5">OR</p>
            <a
              href={googleSignUpURL}
              className="border-2 w-full py-2 px-3 transition duration-500 ease-in-out border-tercen-blue bg-white hover:bg-tercen-blue text-tercen-blue  hover:text-white flex flex-row rounded-sm">
              <svg className="w-4 h-6" viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                  fill="#4285f4"
                />
                <path
                  d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                  fill="#34a853"
                />
                <path
                  d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                  fill="#fbbc04"
                />
                <path
                  d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                  fill="#ea4335"
                />
              </svg>
              <div className="flex-grow px-4 font-medium text-center">Continue with Google</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

interface VideoData {
  /*
  ext: string
  url: string
  mime: string
  */
  publicURL: string
}

interface LandingPage {
  id: string
  metaDescription?: string
  title: string
  heroTitle: string
  heroTagline: string
  imageTagline: string
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string
    }
  }
  landingPage: LandingPage
  heroBackground: SharpImage
  icon1: SharpImage
  icon2: SharpImage
  icon3: SharpImage
  video_webm: VideoData
  video_mp4: VideoData
}

export const pageQuery = graphql`
  query LandingPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    heroBackground: file(relativePath: {eq: "Landing-header_background1.png"}) {
      childImageSharp {
        gatsbyImageData(width: 1900, quality: 70, layout: FULL_WIDTH)
      }
    }
    landingPage: strapiLandingPage(slug: {eq: $slug}) {
      id
      title
      metaDescription
      heroTitle
      heroTagline
      imageTagline
    }
    icon1: file(relativePath: {eq: "easy-to-use.png"}) {
      childImageSharp {
        gatsbyImageData(width: 227, quality: 60)
      }
    }
    icon2: file(relativePath: {eq: "flexible-visualization.png"}) {
      childImageSharp {
        gatsbyImageData(width: 227, quality: 60)
      }
    }
    icon3: file(relativePath: {eq: "work-together.png"}) {
      childImageSharp {
        gatsbyImageData(width: 227, quality: 60)
      }
    }
    video_webm: file(relativePath: {eq: "landing-clip.webm"}) {
      publicURL
    }
    video_mp4: file(relativePath: {eq: "landing-clip.mp4"}) {
      publicURL
    }
  }
`

export default LandingPageTemplate
